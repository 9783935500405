import { Box, Column } from "../../styled";
import { PollCard } from "../PostsCardPoll/Components";
import { ReviewPost } from "../ReviewPost";
import { PostsCardTextV2 } from "../PostsCardText/PostsCardTextV2";
import { SkeltonLoadingCard } from "@/components/SkeltonLoadingCard";
import { MentionType, PostStatus } from "@/gql/graphql";
import { OrgReviewCard } from "../OrgReviewCard";
import { SchoolReviewCard } from "../SchoolReviewCard";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useUserV2 } from "@/hooks/useUserV2";
import { DotsLoading } from "@/components/DotsLoading";
import { Spinner } from "@/components/Spinner";
import { LoadingScreen } from "@/components/Loading";

const PostComponents = {
  TEXT: PostsCardTextV2,
  POLL: PollCard,
  RANKING: ReviewPost,
  ORGANIZATION_REVIEW: OrgReviewCard,
  SCHOOL_REVIEW: SchoolReviewCard,
};

type Post = {
  title: string;
  content: {
    text: string;
  };
  school: {
    logo: string;
    alias: string;
  };
  hashtags: Array<{
    startIndex: number;
    id: string;
    endIndex: number;
  }>;
  mentions: Array<{
    startIndex: number;
    id: string;
    endIndex: number;
    mentionType: MentionType;
    mentionedId: string;
  }>;
  type: string;
  nickname: string;
  upvoteCount: number;
  downvoteCount: number;
  replyCount: number;
  bookmarkCount: number;
  viewCount: number;
  id: string;
  createdAt: Date;
  authorId: string;
  status: PostStatus;
};

type PostsCardFeedProps = {
  data: Post[];
  isDeletedFeed?: boolean;
  loading?: boolean;
  loadMore: () => void;
  notFoundPlaceHolder?: React.ReactNode;
};

export const PostsCardFeedV2: React.FC<PostsCardFeedProps> = ({
  data,
  loading,
  isDeletedFeed,
  loadMore,
  notFoundPlaceHolder,
}) => {
  const { user, error, loading: userLoading } = useUserV2();
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (data) setFirstLoad(false);
  }, [data]);

  useEffect(() => {
    if (inView && !loading) {
      loadMore();
    }
  }, [inView, loading, loadMore]);

  const filteredData = data.filter((post) => {
    if (!user?.flaggedPosts) return true;
    return !user.flaggedPosts.some(
      (flaggedPost) => flaggedPost.postId === post.id
    );
  });

  const renderPost = useCallback(
    (post: Post, index: number) => {
      const Component =
        PostComponents[post.type as keyof typeof PostComponents];
      if (!Component) return null;

      const postProps = {
        cardComments: post.content,
        schoolLogo: post.school.logo,
        tags: post.mentions,
        postBy: post.nickname,
        universityName: post.school.alias,
        comments: post.replyCount,
        views: 2,
        upVotes: post.upvoteCount,
        downVotes: post.downvoteCount,
        sharedPosts: 2,
        viewCount: post.viewCount,
        savedPosts: post.bookmarkCount,
        username: post.nickname,
        id: post.id,
        type: post.type,
        hashtags: post.hashtags,
        title: post.title,
        createdAt: post.createdAt,
        authorId: post.authorId,
        status: post.status,
        isDeletedFeed: isDeletedFeed,
      };

      return (
        <Column key={post.id}>
          <Box>
            <Component {...postProps} />
          </Box>
        </Column>
      );
    },
    [isDeletedFeed]
  );

  if (userLoading)
    return (
      <Box flex={1} alignItems={"center"} justifyContent={"center"}>
        <Box height={40} width={40} color="primary">
          <Spinner />
        </Box>
      </Box>
    );

  if (!loading && data.length === 0) {
    return notFoundPlaceHolder ? notFoundPlaceHolder : null;
  }

  return filteredData ? (
    <Box>
      <Column>
        {filteredData.map(renderPost)}

        <Box ref={ref} style={{ height: "10px" }} />
        {loading && <SkeltonLoadingCard cards={2} />}
      </Column>
    </Box>
  ) : null;
};
